import React from "react";
import "./home.css";

const Home = () => {
  return (
    <div>
      <div className="home-container">
        {/* Header Start */}
        <div className="header-container">
          <div className="header">
            <img src="external/logo.png" className="logo" alt="Logo" />
            <nav className="nav">
              <ul>
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#solution">Solutions</a>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
              </ul>
              <a
                className="book-a-call"
                href="https://calendly.com/auditscan_innovations/30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a Call
              </a>
            </nav>
          </div>
        </div>
        {/* Header End */}
        <div className="home-details" id="home">
          <div className="home-details1">
            <div className="home-container02">
              <h2 className="home-details-heading heading2">
                <span>Asset Tagging &amp; CARO</span>
                <br />
                <span> Reporting</span>
              </h2>
              <span className="home-details-sub-heading">
                <span>
                  At AuditScan Innovations, we specialize in providing
                  comprehensive CARO reporting services to help companies meet
                  their regulatory obligations effectively. Our expert team
                  meticulously examines and assesses compliance with applicable
                  laws and regulations. Through our rigorous CARO reporting
                  process, we aim to provide reliable insights and ensure the
                  integrity and credibility of financial information.
                </span>
                <br />
                <br />
                <span>
                  With AuditScan Innovations, companies can rest assured that
                  their CARO reporting requirements are handled with precision
                  and professionalism, enabling them to maintain transparency,
                  mitigate risks, and foster trust among stakeholders.
                </span>
              </span>
              <div>
                <button className="book-call-btn">
                  Book A Call With Specialist
                </button>
                <button className="learn-more-btn">Learn More</button>
              </div>
            </div>
            <img
              alt="pastedImage"
              src="external/pastedimage-im2-500h.png"
              className="home-pasted-image01"
            />
          </div>
        </div>

        <div className="home-service" id="services">
          <p className="service-heading">Services</p>
          <div className="home-details2">
            <div className="home-details3">
              <img
                alt="pastedImage"
                src="external/pastedimage-ocw-500h.png"
                className="home-pasted-image02"
              />
              <div className="home-container03">
                <h2 className="home-details-heading1 heading2">
                  Fixed Asset Management
                </h2>
                <span className="home-details-sub-heading1">
                  <span>
                    Fixed Asset Management involves the strategic planning,
                    tracking, and optimisation of tangible assets owned by an
                    organisation, such as buildings, machinery, equipment, and
                    vehicles. Efficient management of fixed assets is crucial
                    for maintaining financial stability, operational efficiency,
                    and regulatory compliance.
                  </span>
                  <br />
                  <br />
                  <span className="home-text10">
                    Key components of Fixed Asset Management include:
                  </span>
                  <br className="home-text11" />
                  <br />
                  <span className="home-text13">(1) Asset Transfer:</span>
                  <span>
                    Physical transfer involves relocating the assets from one
                    location to another. This may require coordination between
                    departments, facilities management, or logistics teams to
                    ensure safe and efficient transportation.
                  </span>
                  <br />
                  <br />
                  <span className="home-text17">(2) Asset Tracking:</span>
                  <span>
                    Implementing systems to accurately track the location,
                    condition, and usage of fixed assets throughout their
                    lifecycle. This includes barcode or RFID tagging, asset
                    registers, and asset management software.
                  </span>
                  <br />
                  <br />
                  <span className="home-text21">
                    (3) Asset Lifecycle Management:
                  </span>
                  <span>
                    Regular maintenance activities, such as inspections,
                    repairs, and upgrades, are documented in the asset
                    management system. The custodian is responsible for updating
                    the asset's location in the system whenever it is relocated
                    within the organization. This ensures accurate tracking of
                    assets across different departments or locations.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="home-features" id="solution">
          <div className="home-features-container">
            <div className="home-features1">
              <span className="sectionTitle">
                <span>Solutions</span>
                <br />
              </span>
              <div className="home-container04">
                <h2 className="home-features-heading heading2">
                  Powerful Features for Effective Asset Management
                </h2>
              </div>
              <div className="home-container05">
                <div className="home-feature-card">
                  <div className="home-container06">
                    <div className="home-container07">
                      <img
                        alt="pastedImage"
                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/2336be39-af04-4496-8473-44ccbd09f3af/baeb5766-e2b9-49dc-9a7d-b60c6b77374d?org_if_sml=122743&force_format=original"
                        className="home-pasted-image03"
                      />
                    </div>
                    <h3 className="home-text26 heading3">Asset Management</h3>
                  </div>
                  <span className="home-text27">
                    Efficiently track and manage your fixed assets
                  </span>
                </div>
                <div className="home-feature-card">
                  <div className="home-container08">
                    <div className="home-container09">
                      <img
                        alt="pastedImage"
                        src="external/pastedimage-aasa-200h.png"
                        className="home-pasted-image04"
                      />
                    </div>
                    <h3 className="home-text28 heading3">Verification</h3>
                  </div>
                  <span className="home-text29">
                    Ensure accurate and up-to-date asset information through
                    verification processes
                  </span>
                </div>
                <div className="home-feature-card">
                  <div className="home-container10">
                    <img
                      alt="pastedImage"
                      src="external/pastedimage-f02e-200h.png"
                      className="home-pasted-image05"
                    />
                    <h3 className="home-text30 heading3">Tagging</h3>
                  </div>
                  <span className="home-text31">
                    Easily label and identify assets with unique tags for quick
                    retrieval
                  </span>
                </div>
                <div className="home-feature-card">
                  <div className="home-container11">
                    <div className="home-container12">
                      <img
                        alt="pastedImage"
                        src="external/pastedimage-yup8-200h.png"
                        className="home-pasted-image06"
                      />
                    </div>
                    <h3 className="home-text32 heading3">Reporting</h3>
                  </div>
                  <span className="home-text33">
                    Generate comprehensive reports on asset status,
                    depreciation, and more
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-ams-solution">
          <h2 className="ams-service-heading">Our Ams Solutions</h2>
          <div className="home-faq">
            <div className="home-faq-container">
              <div className="home-faq1">
                <div className="home-container13">
                  <div className="home-container14">
                    {/* <h2 className="home-text34 heading2">Our AMS Solution</h2> */}
                  </div>
                  <img
                    alt="pastedImage"
                    src="external/image_7-removebg-preview.png"
                    className="home-pasted-image07"
                  />
                </div>
                <div className="home-container15">
                  <div className="home-container16">
                    <span className="home-text35 heading4">
                      Digitized Fixed Asset Register (FAR) in line with CARO
                      Compliance
                    </span>
                  </div>
                  <div className="home-container16">
                    <span className="home-text36 heading4">
                      Real-time Location/Sub Location of Assets
                    </span>
                  </div>
                  <div className="home-container16">
                    <span className="home-text37 heading4">
                      Addition/Deletion of New Assets
                    </span>
                  </div>
                  <div className="home-container16">
                    <span className="home-text38 heading4">
                      Automated Physical Verification &amp; Audits
                    </span>
                  </div>
                  <div className="home-container16">
                    <span className="home-text39 heading4">
                      Transfers &amp; Disposal Process
                    </span>
                  </div>
                  <div className="home-container16">
                    <span className="home-text41 heading4">
                      IT Asset Life Cycle Management
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Map start */}
        <div className="container" id="about">
          <div className="map">
            {/* <img src="https://i.imgur.com/vN6g54B.png" alt="Map"> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3501.4784015660753!2d77.24220607550127!3d28.64539097565766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM4JzQzLjQiTiA3N8KwMTQnNDEuMiJF!5e0!3m2!1sen!2sin!4v1721042051223!5m2!1sen!2sin"
              width="100%"
              height="680"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
          <div className="info">
            <h2 className="office">OUR OFFICES</h2>
            <div className="address-cont">
              <div className="location">
                <i className="location-icon">&#9742;</i>
                <div className="location-title">Delhi</div>
              </div>
              <div className="location-details">
                <p className="address">
                  5A/15, Ansari Road, Darya Ganj, New Delhi - 110002
                </p>
                <p className="tel">+91 9716630321</p>
              </div>
            </div>
            <div className="address-cont">
              <div className="location">
                <i className="location-icon">&#9742;</i>
                <div className="location-title">Ghaziabad</div>
              </div>
              <div className="location-details">
                <p className="address">
                  E-168, Nandgram, Ghaziabad, U.P. - 201003
                </p>
                <p className="tel">+91 8130812821</p>
              </div>
            </div>
          </div>
        </div>
        {/* Map end */}
        <div className="home-hero">
          <div className="home-pasted-image08"></div>
          <div className="home-hero1">
            <div className="home-container21">
              <h3 className="home-hero-heading heading2">
                From Tags to Triumph
              </h3>
              <span className="home-hero-sub-heading">
                Streamline Your Asset Verification And &nbsp;Management Process
                With Our Professional &amp; Experienced Team
              </span>
              <div className="home-btn-group">
                <a
                  href="https://calendly.com/auditscan_innovations/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="book-call-btn2"
                >
                  Book A Call
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="home-footer">
          <footer className="home-footer1">
            <div className="home-container22">
              <span className="home-logo1">AuditScan Innovations</span>
              <nav className="home-nav1"></nav>
            </div>
            <div className="home-container23">
              <nav className="home-nav2"></nav>
            </div>
            <div className="home-separator"></div>
            <div className="home-container24">
              <span className="home-logo2">
                AuditScan Innovations implemented solutions to track assets,
                inventory, finished goods, tools, fleet, delivery, consumables,
                employees, documentation, remote sites etc. almost everything
                that needs to be tracked.
              </span>
            </div>
            <div className="home-container25">
              <span className="home-text42">
                © 2018-24, All Rights Reserved.
              </span>
              <div className="home-icon-group1">
                <img
                  alt="pastedImage"
                  src="external/pastedimage-jt1h-200w.png"
                  className="home-pasted-image09"
                />
                <a href='https://www.linkedin.com/company/auditscan-innovations/'>
                <img
                  alt="pastedImage"
                  src="external/pastedimage-525uj-200h.png"
                  className="home-pasted-image10"
                />
                </a>
                <a href='https://www.youtube.com/channel/UCGpYK7vHJnyt2avwbL5Gu6Q'>
                <img
                  alt="pastedImage"
                  src="external/pastedimage-j9cg-200h.png"
                  className="home-pasted-image11"
                />
                </a>
                {/* <img
                  alt="pastedImage"
                  src="external/pastedimage-rtj-200h.png"
                  className="home-pasted-image12"
                /> */}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Home;
